
settings =
	revealStart: -> "top bottom"
	revealEnd: -> "50% bottom"

import inViewportMixin from 'vue-in-viewport-mixin'
export default

	props: block: Object

	mixins: [ inViewportMixin ]

	computed:

		# Root classes
		classes: -> [
			"align-#{@block.textAlign}"
			@block.maxWidth || 'max-w-small' # Default for backwards compat
			"animate" if @animate
		]

		animate: -> @block.animate

	watch: 'inViewport.now': (inViewport) -> if inViewport then @$nextTick => @revealWhenInside()

	methods:
		revealWhenInside: ->
			return unless @animate
			@timeline = @$gsap.timeline()
				.pause()
				.fromTo @$refs.wrap, { opacity: -> 0 }, { opacity: -> 1 }

			@trigger1 = @$ScrollTrigger.create {
				trigger: @$el
				start: settings.revealStart
				end: settings.revealEnd
				animation: @timeline
			}


